



















































































































import {Component, Mixins, Ref, Watch} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import Options from "@/models/vue/Options";
import Client from "@/models/Client";
import ClientService from "@/services/ClientService";
import SessionModule from "@/store/SessionModule";
import ClientDialog from "@/components/dialog/ClientDialog.vue";
import LangModule from "@/store/LangModule";
import {AuthorityName} from "@/models/Authority";
import PaginationMixin from "@/mixins/PaginationMixin";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import SessionMixin from "@/mixins/SessionMixin";

@Component({components: {ClientDialog}})
export default class ClientsView extends Mixins(PaginationMixin, SessionMixin) {
    lang: any = getModule(LangModule).lang
    sessionModule: SessionModule = getModule(SessionModule)
    @Ref() readonly form!: HTMLFormElement
    dialog: boolean = false
    clients: Client[] = []
    clients2: MultipleItem<Client> = { items: [], totalItems: 0 }
    active: boolean = false
    size = 10
    filterMyClients: boolean = false
    all: boolean = false
    pageCount: number = 0
    loading: boolean = false
    itemsPerPage: number = 10
    totalItems: number = 0
    options: Options = new Options()
    search: string = ""

    authority?: string = this.sessionModule.session.authorities[0].name

    get headers() {

        let headers = [
            {text: this.lang.name, divider: true, value: "name", width: "auto", align: "center"},
            {text: this.lang.commercialName, divider: true, value: "commercialName", width: "auto", align: "center"},
            {text: this.lang.email, divider: true, value: "email", width: "auto", align: "center"},
            {text: this.lang.phone, divider: true, value: "phone", width: "auto", align: "center"},
            {text: this.lang.enabled, divider: true, value: "enabled", width: "auto", align: "center"},
        ]

        if (this.authority != AuthorityName.COMMERCIAL) {
            headers.push({text: this.lang.createdBy, divider: true, value: "createdBy", width: "auto", align: "center"})
        }

        return headers
    }

    email: string = ""
    name: string = ""
    commercialName: string = ""

    created() {
        if (this.$route.query.all == "1") {
            this.all = true
        }
        this.refresh()
    }

    async refresh() {
        if (this.isCommercial) {
            await Handler.getItems(this, this.clients2, () =>
                ClientService.getMyOwnClients(this.page - 1, this.size, this.search)
            )
        } else if (this.isDistributor || this.isCompanyManager) {
            if (this.filterMyClients) {
                await Handler.getItems(this, this.clients2, () =>
                    ClientService.getMyOwnClients(this.page - 1, this.size, this.search)
                )
            } else {
                await Handler.getItems(this, this.clients2, () =>
                    ClientService.getClientsUltimate(this.page - 1, this.size, this.search, null, null)
                )
            }
        }
        this.setPageCount(this.clients2.totalItems!!)

    }

    rowClick(client: Client) {
        this.$router.push({path: "/clients/" + client.id})
    }

    openCreateDialog() {
        if (this.form) this.form.resetValidation()
        this.dialog = true
    }

    convertToCSV() {
        ClientService.getMyClientCSV(this, this.page - 1, this.itemsPerPage, this.search, true, !this.active)
    }

    onSearchInputChanged() {
        this.page = 1
        this.refresh()
    }

    @Watch("filterMyClients")
    @Watch("page")
    onPageChanged() {
        this.refresh()
    }

}
